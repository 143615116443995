import React from 'react';
import '../style/style.css';
import { MiniProject as MiniPro } from "../interfaces/interfaces";
import { translate } from '../util/translate';
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    miniProject: MiniPro,
    currentLanguage: string,
}

interface IState {
}

class MiniProject extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { miniProject, currentLanguage } = this.props;
        return (
            <>{ miniProject.name } { translate(miniProject.description, currentLanguage) }</>
        );
    }
}

export default withTranslation()(MiniProject);