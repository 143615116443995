import React from 'react';
import '../style/style.css';
import { Study as Stu } from "../interfaces/interfaces";
import { translate } from '../util/translate';
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    study: Stu,
    currentLanguage: string,
}

interface IState {
}

class Study extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { study, currentLanguage } = this.props;
        return (
            <>{ translate(study.description, currentLanguage) }</>
        );
    }
}

export default withTranslation()(Study);