import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import Main from './components/Main';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_eu from "./translations/eu/common.json";
import common_es from "./translations/es/common.json";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'eu', // default language
    resources: {
        eu: {
            common: common_eu
        },
        es: {
            common: common_es
        },
    },

});

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Main />
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
