import React from 'react';
import '../style/style.css';
import aerana from "../data/aerana.json";
import cgonzalez from "../data/cgonzalez.json";
import { InfoData } from "../interfaces/interfaces";
import LanguageSelector from './LanguageSelector';
import Language from './Language';
import Experience from './Experience';
import Study from './Study';
import Skill from './Skill';
import MiniProject from './MiniProject';
import { withTranslation, WithTranslation } from "react-i18next";
import i18n, { TFunction } from 'i18next';
import { existImage } from '../util/img';
import { defineTitle, defineFavicon } from '../util/header';

interface IProps extends WithTranslation {
}

interface IState {
    language: string;
}

class Main extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            language: "eu",
        };
    }

    componentDidMount() {
        const data = getData();
        defineTitle(data.name + ' ' + data.surname);
        defineFavicon(data.favicon);
    }

    render() {
        const data = getData();
        const { t } = this.props;
        return (
            <>
                {/* { data.viewLanguages.map(language =>
                    <LanguageSelector
                        key={ language.code }
                        language={ language }
                        currentLanguage={ this.state.language }
                        active={ language.code === this.state.language }
                        onClick={() => {
                            this.setState({ ...this.state, language: language.code });
                            i18n.changeLanguage(language.code);
                        }}
                    >
                        { language.name }
                    </LanguageSelector>
                )}
                <br /> */}
                <b>{ t('name') }:</b> { data.name }<br />
                {/* <b>{ t('surname') }:</b> { data.surname }<br />
                { renderImage(data.img, data.imgAlt) }<br />
                <b>{ t('email') }:</b> <a href={ `mailto:${data.email}` }>{ data.email }</a><br />
                <b>{ t('phone') }:</b> { data.phone }<br />
                <a href={ data.linkedin.link } target="_blank" rel="noreferrer">{ data.linkedin.name }</a><br />
                { renderLanguages(data.languages, this.state.language, t) }<br />
                { renderExperiences(data.experiences, this.state.language, t) }<br />
                { renderStudies(data.studies, this.state.language, t) }<br />
                { renderSkills(data.skills, this.state.language, t) }<br />
                { renderMiniProjects(data.miniProjects, this.state.language, t) }<br /> */}
            </>
        );
    }
}

function renderImage(image: string, imageAlt: string) {
    if (image && existImage(image)) {
        return (
            <img src={ image } alt={ imageAlt } />
        );
    }
}

function renderLanguages(languages: InfoData["languages"], currentLanguage: string, t: TFunction) {
    if (languages.length > 0) {
        return (
            <>
                <b>{ t('languages') }:</b>
                {languages.map(language => <Language key={ language.code } language={ language } currentLanguage={ currentLanguage } /> )}
            </>
        );
    }
}

function renderExperiences(experiences: InfoData["experiences"], currentLanguage: string, t: TFunction) {
    if (experiences.length > 0) {
        return (
            <>
                <b>{ t('experiences') }:</b>
                { experiences.map(experience => <Experience key={ experience.code } experience={ experience } currentLanguage={ currentLanguage } /> )}
            </>
        );
    }
}

function renderStudies(studies: InfoData["studies"], currentLanguage: string, t: TFunction) {
    if (studies.length > 0) {
        return (
            <>
                <b>{ t('studies') }:</b>
                { studies.map(study => <Study key={ study.code } study={ study } currentLanguage={ currentLanguage } /> )}
            </>
        );
    }
}

function renderSkills(skills: InfoData["skills"], currentLanguage: string, t: TFunction) {
    if (skills.length > 0) {
        return (
            <>
                <b>{ t('skills') }:</b>
                { skills.map(skill => <Skill key={ skill } skill={ skill } currentLanguage={ currentLanguage } /> )}
            </>
        );
    }
}

function renderMiniProjects(miniProjects: InfoData["miniProjects"], currentLanguage: string, t: TFunction) {
    if (miniProjects.length > 0) {
        return (
            <>
                <b>{ t('miniProjects') }:</b>
                { miniProjects.map(miniProject => <MiniProject key={ miniProject.code } miniProject={ miniProject } currentLanguage={ currentLanguage } /> )}
            </>
        );
    }
}

function getData(): InfoData {
    var data = { aerana: aerana, cgonzalez: cgonzalez }[process.env.REACT_APP_DATA_NAME!] as InfoData;
    data.linkedin = data.linkedin ? data.linkedin : { "name": "", "link": "" };
    data.linkedin.name = data.linkedin.name ? data.linkedin.name : '';
    data.linkedin.link = data.linkedin.link ? data.linkedin.link : '';
    data.viewLanguages = data.viewLanguages ? data.viewLanguages.filter(language => language.visible) : [];
    data.languages = data.languages ? data.languages.filter(language => language.visible) : [];
    data.experiences = data.experiences ? data.experiences.filter(experience => experience.visible) : [];
    data.studies = data.studies ? data.studies.filter(study => study.visible) : [];
    data.skills = data.skills ? data.skills : [];
    data.miniProjects = data.miniProjects ? data.miniProjects.filter(project => project.visible) : [];
    return data;
}

export default withTranslation(['common'])(Main);