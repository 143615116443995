import React from 'react';
import '../style/style.css';
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps extends WithTranslation {
    skill: string,
    currentLanguage: string,
}

interface IState {
}

class Skill extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { skill } = this.props;
        return (
            <>{ skill }</>
        );
    }
}

export default withTranslation()(Skill);